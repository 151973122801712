import "./style.css";
import "./efeito.js";
import Logo from "../../assets/Logo.png";
import Vector from "../../assets/Menino.jpg";
import Book from "../../assets/Book.svg";
import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import { FaInstagram, FaFacebook, FaWhatsapp, FaBookOpen } from "react-icons/fa";

function Main(){
    useEffect(() => {
        const sr = ScrollReveal();
        sr.reveal('.headline', {
          duration: 1000,
          origin: 'bottom',
          distance: '20px',
          delay: 0,
          easing: 'cubic-bezier(0.5, 0, 0, 1)',
        });

        sr.reveal('#main-content', {
            duration: 1000,
            origin: 'bottom',
            distance: '20px',
            delay: 0,
            easing: 'cubic-bezier(0.5, 0, 0, 1)',
          });
      }, []);

    return (
        <body>
            <header>
                <section id="header-content">
                    <img id="header-image" src={Logo}></img>
                    <div className="header-titles">
                        <h1 id="header-titulo">BIBLIOTECA</h1>
                        <h1 id="header-titulo">COMUNITÁRIA</h1>
                        <h1 id="header-titulo">JOSÉ GENEROSI</h1>
                    </div>
                </section>

                <nav id="header-nav">
                    <ul id="header-lista">
                        <li id="header-lista-item">Pagina Inícial</li>
                        <li id="header-lista-item">Saiba Mais</li>
                        <li id="header-lista-item">Contato</li>
                        <li id="header-lista-item">FAQ</li>
                    </ul>
                </nav>
            </header>

            <div id="separador">
                <span>BC - JOSÉ GENEROSI</span>
            </div>

            <main>
                <div id="main-content">
                    <h2 id="main-title">Por quê a leitura é importante?</h2>
                    <p id="main-p">A leitura é fundamental para o crescimento pessoal. Ela nos transporta para novos mundos, estimula nossa imaginação e amplia nossa compreensão do mundo. Além disso, fortalece nossas habilidades de comunicação e pensamento crítico. Em resumo, ler não é apenas um hobby, mas uma ferramenta poderosa para o desenvolvimento pessoal e intelectual.</p>
                </div>

                <img id="main-image" src={Vector}></img>
            </main>

            <div id="imagem-div">
                <p id="imagem-texto">A leitura é uma janela para o mundo, e todos têm o direito de explorar novos horizontes e expandir seus conhecimentos. Vamos cultivar uma sociedade onde o amor pelos livros e o acesso à literatura sejam direitos fundamentais de cada indivíduo.</p>
            </div>

            <section id="quem-somos" className="headline">
                <img id="quem-somos-image" src={Book}></img>
                <div id="quem-somos-content">
                    <h2 className="quem-somos-title">Missão</h2>
                    <p className="quem-somos-text">Nossa missão é garantir que todos os alunos tenham acesso equitativo a recursos educacionais e literários, promovendo a leitura como uma ferramenta fundamental para o desenvolvimento pessoal e acadêmico. Focamos na criação de espaços colaborativos e inclusivos, onde a troca de conhecimentos e experiências fortaleça nossa comunidade escolar e prepare nossos alunos para um futuro de aprendizado contínuo e enriquecedor.</p>
                    <div className="quem-somos-div">
                        <h2 className="quem-somos-title .title2">Visão</h2>
                        <p className="quem-somos-text">Imaginamos um futuro onde cada aluno tenha acesso fácil e gratuito a uma ampla variedade de livros, promovendo uma cultura de leitura e aprendizado contínuo. Queremos criar um ambiente inclusivo e colaborativo onde o compartilhamento de conhecimento e histórias enriqueça as vidas de todos os membros da nossa comunidade escolar.</p>
                    </div>
                </div>
            </section>

            <footer>
                <section id="footer-section">
                    <div id="footer-content">
                        <h2 id="footer-title">Leia na Quadra</h2>
                    </div>

                    <p id="footer-texto">Em caso de dúvidas: Entre em Contato. Trabalho criado para iniciação científica.</p>
                </section>

                <section id="footer-section">
                    <h3>Veja Mais</h3>

                    <nav>
                        <ul>
                            <li className="footer-lista-item">Pagina Inícial</li>
                            <li className="footer-lista-item">Saiba Mais - Em Breve</li>
                            <li className="footer-lista-item">Contato - Em Breve</li>
                            <li className="footer-lista-item">FAQ - Em Breve</li>
                        </ul>
                    </nav>
                </section>

                <section  id="footer-section">
                    <h3>Contato</h3>
                    <a className="footer-infos-link" href="https://wa.me/54984430869?text=Olá!+Tenho+dúvidas+sobre+a+biblioteca+comunitária+no+JG" target="_blank"><FaWhatsapp /> Whatsapp</a>
                </section>

                <section id="footer-redes">
                    <h3>Apoie o Projeto</h3>

                    <a className="footer-infos-link" href="https://www.instagram.com/vini_cxs/" target="_blank"><FaInstagram /> Instagram</a>
                    <a className="footer-infos-link" href="https://www.instagram.com/vini_cxs/" target="_blank"><FaFacebook /> Facebook - EM BREVE</a>
                </section>
            </footer>
        </body>
    )
}

export default Main
