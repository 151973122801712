import './App.css';
import Main from './Pages/Home';

function App() {
  return (
    <Main />
  );
}

export default App;
